<template>
  <!--登录页面-->
  <div class="content">
    <!--表单-->
    <div class="login-box">
      <!--左-->
      <div class="left">
        <img src="@/view/images/login_img.png" />
      </div>

      <!--右-->
      <transition name="slide-fade" mode="out-in">
          <div v-if="rightViewType === 0" class="right">
            <div class="language">
              <img class="language-img" src="@/assets/language.png" />
              <el-select
                  v-model="language"
                  class="select"
                  placeholder="请选择"
                  style="width: 110px;margin-right:10px"
                  @change="handleChange"
              >
                <el-option
                    v-for="(item,index) in languageList"
                    :key="index"
                    :label="item.name"
                    :value="item.label"
                ></el-option>
              </el-select>
            </div>
            <!--头-->
            <div class="header">
              <!--标题-->
              <h6 class="title">{{ $t('login') }}</h6>
              <!--右侧按钮-->
              <div class="title-right">
                <transition name="slide-fade" mode="out-in">
                  <el-button
                      v-if="account"
                      type="text"
                      @click="tab"
                      key="vfcode"
                      class="is-black"
                  >
                    {{ $t('verification_code_login') }}
                  </el-button>

                  <el-button
                      v-else
                      type="text"
                      @click="tab"
                      key="password"
                      class="is-black"
                  >
                    {{ $t('account_password_login') }}
                  </el-button>
                </transition>
              </div>
            </div>

            <!--表单-->
            <el-form
              ref="para"
              :model="para"
              :rules="rules"
              size="medium"
            >
              <el-form-item prop="phone">
                <el-input
                  v-model.number="para.phone"
                  v-on:input="getPhoneString"
                  :placeholder="$t('phone_number')"
                  maxlength="11"
                />
              </el-form-item>

              <transition name="slide-fade" mode="out-in">
                <!--密码-->
                <el-form-item
                  v-if="account"
                  key="password_input"
                  prop="password"
                >
                  <el-input
                    v-model.trim="para.password"
                    :type="passwordType ? 'text' : 'password'"
                    :placeholder="$t('password')"
                    maxlength="16"
                  >
                    <svg-icon
                      slot="suffix"
                      :icon-class="passwordType ? 'eye-open' : 'eye'"
                      @click="passwordType = !passwordType"
                    />
                  </el-input>
                </el-form-item>

                <!--验证码-->
                <el-form-item
                  v-else
                  key="vf_input"
                  prop="code"
                >
                  <div class="d-flex flex-align-h-between">
                    <el-input v-model.trim="para.code" :placeholder="$t('login_verification_code')" />
                    <div class="ml-20" style="width: 130px;flex:0 0 auto">
                      <el-button v-if="AuthCode" type="primary" class="width-full" :style="language === 'en' ?  {'padding': '10px 0px'}: ''" @click="checkVerification">{{ $t('get_verification_code') }}</el-button>
                      <el-button v-else disabled type="primary" class="width-full again-btn" :style="language === 'en' ?  {'padding': '10px 0px'}: ''">{{ $t('resend_in') }}({{ auth_time }}s)</el-button>
                    </div>
                  </div>
                </el-form-item>
              </transition>
            </el-form>

            <!--按钮栏-->
            <div class="btn-group">
              <el-button type="text" @click="ToRegister">{{ $t('account_registration') }}</el-button>
              <transition name="slide-fade" mode="out-in">
                <el-button v-if="account" type="text" @click="ToForget">{{ $t('forgot_password') }}</el-button>
              </transition>
            </div>

           <!-- <div class="remenber">
              <el-checkbox v-if="account" v-model="checked" @click="tab">记住密码</el-checkbox>
            </div>-->

            <el-button
              :loading='loading'
              type="primary"
              size="medium"
              @click="checkLogin"
              class="width-full mb-15"
            >
              {{ $t('login') }}
            </el-button>

            <!--协议-->
            <div class="footer">
              {{ $t('Logging_indicates') }}
              <el-button type="text" @click="contract">{{ $t('Service_Agreement') }}</el-button>
              <span> {{ $t('and') }} </span>
              <el-button type="text" @click="Privacy">{{ $t('Privacy_Policy') }}</el-button>
            </div>
          </div>
          <!--找回密码-->
          <Forget v-if="rightViewType == 2" v-on:rightViewType="changeRightViewType($event)" />
        </transition>
    </div>
  </div>
</template>

<script>
import API from '../../api';
import cookies from '../../utils/cookies';
import Forget from './forget.vue';

export default {
  name: 'LoginPage',
  components: {
    Forget,
  },
  data() {
    return {
      loading: false, // 加载
      passwordType: false,
      VUE_APP_HOME: process.env.VUE_APP_HOME,
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE,
      account: true, // 账号登录
      vftion: false, // 验证码登录
      auth_time: 60, // 60秒倒计时
      AuthCode: true, // 显示获取验证码登录
      phone: '',
      rightViewType: 0,
      // 账号登录
      para: {
        phone: '', // 账号
        password: undefined, // 密码
        code: undefined,
        type: 'PORTAL',
        language: ''
      },
      LoginType: '',
      checked: false,
      language: localStorage.getItem('locale') === 'zh' ? 'zh' : !localStorage.getItem('locale') ? 'zh' : 'en',
      rules: {
        phone: [
          { required: true, message: this.$t('please_enter_phone_number'), trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: this.$t('please_enter_valid_phone_number'), trigger: 'blur' },
        ],
        password: [{ required: true, message: this.$t('please_enter_password'), trigger: 'blur' }],
        code: [
          { required: true, message: this.$t('verification_code'), trigger: 'blur' },
          { pattern: /^\d{6}$/, message: this.$t('correct_verification_code_format'), trigger: 'blur' },
        ],
      },
      // 验证码登录
      code: {
        phone: '',
        code: '',
        language: ''
      },
      token: '',
      userInfo: '',

      languageList: [
        {name: '简体中文',label: 'zh'},
        {name: 'English',label: 'en'}
      ]
    };
  },
  computed: {
    language() {
      return localStorage.getItem('locale') === 'en' ? 'en' : 'zh';
    }
  },
  created() {
    const check = cookies.get('check');
    this.checked = Boolean(check);
    if (this.checked) {
      this.remenber();
    }
    console.log(localStorage.getItem('locale'))
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false);
  },
  methods: {
    // 记住密码
    remenber() {
      this.para.phone = cookies.get('phone');
      this.para.password = cookies.get('passWord');
    },
    handleChange(e) {
      this.language = e;
      if(e === 'zh') {
        localStorage.setItem('locale', 'zh')
      } else if(e === 'en') {
        localStorage.setItem('locale', 'en')
      } else {
        localStorage.setItem('locale', 'zh')
      }
      this.$i18n.locale = e
      this.$forceUpdate()
      console.log( localStorage.getItem('locale'))
    },
    // 协议
    contract() {
      if (localStorage.getItem('locale') === 'en') {
        const routeData = this.$router.resolve({ path: '/agreementEn' });
        window.open(routeData.href, '_blank');
      } else {
        const routeData = this.$router.resolve({ path: '/agreement' });
        window.open(routeData.href, '_blank');
      }

      // window.open('https://www.ygspii.cn/page_agreement_regist.html')
      // window.open('https://www.cpct.net.cn/page_agreement_regist.html')
    },
    // 隐私
    Privacy() {
      if (localStorage.getItem('locale') === 'en') {
        const routeData = this.$router.resolve({ path: '/privacyEn' });
        window.open(routeData.href, '_blank');
      } else {
        const routeData = this.$router.resolve({ path: '/privacy' });
        window.open(routeData.href, '_blank');
      }

      // window.open('https://www.ygspii.cn/page_agreement_privacyPolicy.html')
      // window.open('https://www.cpct.net.cn/page_agreement_privacyPolicy.html')
    },
    // 快捷键登录
    keyDown(e) {
      if (e.keyCode === 13) {
        if (!this.loading) {
          this.checkLogin();
        }
      }
    },
    // 切换登录方式
    tab() {
      this.para = {
        phone: '',
        password: undefined,
        code: undefined,
        type: 'PORTAL',
      };
      this.account = !this.account;
      this.vftion = !this.vftion;
    },
    ToRegister() {
      document.title = this.$t('register');
      this.$router.push({path: '/register',query: {path: this.$route.path}});
      // this.rightViewType = 1
    },
    ToForget() {
      // this.$router.push('/forget')
      this.rightViewType = 2;
    },
    changeRightViewType(type) {
      this.rightViewType = type;
    },
    getPhoneString() {
      // this.para.phone = this.para.phone.replace(undefined, undefined)
    },
    // 判断手机号格式
    // checkAccount() {
    //   let reg = /^1[3|4|5|7|8][0-9]{9}$/
    //   let res = reg.test(this.para.phone.trim())
    //   return res
    // },
    // 判断密码登录或者验证码登录
    checkLogin() {
      [, this.LoginType] = this.$route.params.type.split(':');
      if (this.account) {
        this.$refs.para.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.AccountLogin();
          }
        });
      } else {
        if (this.language === 'zh') {
          this.para.language = 'zh_CN'
        } else {
          this.para.language = 'en_US'
        }
        this.$refs.para.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.VerificationLogin();
          }
        });
      }
      // this.$refs.para.validate(valid => {
      //   if (valid) {
      // if (this.para.phone.trim().length <= 0) {
      //   this.$message({
      //     message: '请输入手机号',
      //     type: 'error',
      //     duration: 1000
      //   })
      //   return
      // } else if (!this.checkAccount()) {
      //   this.$message({
      //     message: '请输入正确的手机号',
      //     type: 'error',
      //     duration: 1000
      //   })
      //   return
      // } else if (this.para.password.length <= 0) {
      //   this.$message({
      //     message: '请输入密码',
      //     type: 'error',
      //     duration: 1000
      //   })
      //   return
      // } else {
      // 调取密码登录
      // this.VerificationLogin()

      // }
      //   }
      // })
    },
    // 账号登陆
    AccountLogin() {
      [, this.LoginType] = this.$route.params.type.split(':');
      this.getPhoneString();
      console.log(this.language)
      if (this.language === 'zh') {
        this.para.language = 'zh_CN'
      } else {
        this.para.language = 'en_US'
      }
      API.Alogin(this.para).then((res) => {
        if (res.message.success === true) {
          this.$message({
            message: res.message.message,
            type: 'success',
          });
          if (this.checked === true) {
            cookies.set('check', this.checked);
            cookies.set('phone', this.para.phone);
            cookies.set('passWord', this.para.password);
            cookies.set('language', this.language);
          }
          this.token = res.message.data;
          cookies.set('token', this.token);
          API.userLog({ code: 'login' }).then(() => {
            // console.log(res)
          });
          this.getInfo();
        } else {
          this.$message({
            message: res.message.message,
            type: 'error',
          });
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    getInfo() {
      API.information({ token: this.token })
        .then((res) => {
          if (res.message.success) {
            this.userInfo = res.message.data;
            cookies.set('token', this.token);
            cookies.set('userInfo', this.userInfo.power);
            cookies.set('teamId', this.userInfo.teamId);
            if (this.LoginType === 'SUP') {
              if (this.userInfo.teamId) {
                console.log(this.userInfo.roleId)
                if (this.userInfo.roleId === '5a729bb3-f6c7-499b-b3c2-0193a99addda' || this.userInfo.roleId === '3644fc5d-ef69-4a30-8dad-6407b3120eed' || this.userInfo.roleId === '0ea0531a-94c9-4b57-90cb-7315b8c2b961') {
                  if (this.$route.query.YGS) {
                    localStorage.setItem('YGS', 'PringManager')
                    window.open(`${this.VUE_APP_USERCONSOLE}PrintSteward/firstHome?YGS=PringManager`, '_self');
                  } else {
                    window.open(`${this.VUE_APP_USERCONSOLE}PrintSteward/firstHome`, '_self');
                  }
                } else {
                  if (this.$route.query.YGS) {
                    localStorage.setItem('YGS', 'PringManager')
                    window.open(`${this.VUE_APP_USERCONSOLE}PrintSteward/PrintHome?YGS=PringManager`, '_self');
                  } else {
                    window.open(`${this.VUE_APP_USERCONSOLE}PrintSteward/PrintHome`, '_self');
                  }
                }
                //
              } else {
                window.open(`${this.VUE_APP_USERCONSOLE}Course`, '_self');
              }
            } else {
              window.open(this.VUE_APP_HOME, '_self');
            }
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 验证码手机号格式
    checkPhone() {
      const reg = /^1[3-9][0-9]{9}$/;
      const res = reg.test(this.para.phone);
      return res;
    },
    // 验证码格式
    // cheackCode() {
    //   let reg = /^\d{6}$/
    //   let res = reg.test(this.code.code.trim())
    //   return res
    // },
    // 判断手机号格式并获取验证码
    checkVerification() {
      this.getPhoneString();
      if (this.para.phone.length <= 0) {
        this.$message({
          message: this.$t('mobile_number_cannot_empty'),
          type: 'error',
          duration: 1000,
        });
      } else {
        this.getcode();
        this.AuthCode = false;
        this.auth_time = 60;
        const authTimetimer = setInterval(() => {
          this.auth_time -= 1;
          if (this.auth_time <= 0) {
            this.AuthCode = true;
            clearInterval(authTimetimer);
          }
        }, 1000);
      }
    },
    // 获取验证码
    getcode() {
      this.getPhoneString();
      this.code.phone = this.para.phone;
      if (this.language === 'zh') {
        this.code.language = 'zh_CN'
      } else {
        this.code.language = 'en_US'
      }
      API.getCode(this.code).then((res) => {
        if (res.message.success) {
          this.$message({
            message: res.message.message,
            type: 'success',
            duration: 1000,
          });
        } else {
          this.$message({
            message: res.message.message,
            type: 'warning',
            duration: 1000,
          });
        }
      });
      // .catch(mgs => {
      //   this.$message({
      //     message: mgs.message.message,
      //     type: 'error',
      //     duration: 1000
      //   })
      // })
    },
    // 判断验证码格式并登录
    // submit() {
    //   this.getPhoneString()
    //   if (this.para.phone.trim().length <= 0) {
    //     this.$message({
    //       message: '手机号不能为空',
    //       type: 'error',
    //       duration: 1000
    //     })
    //   } else if (!this.checkPhone()) {
    //     this.$message({
    //       message: '请输入正确的手机号',
    //       type: 'error',
    //       duration: 1000
    //     })
    //   } else if (this.code.code.length <= 0) {
    //     this.$message({
    //       message: '验证码不能为空',
    //       type: 'error',
    //       duration: 1000
    //     })
    //   } else if (!this.cheackCode()) {
    //     this.$message({
    //       message: '请输入正确验证码格式',
    //       type: 'error',
    //       duration: 1000
    //     })
    //   } else {
    //     // 调取验证码登录
    //     this.VerificationLogin()
    //   }
    // },
    // 验证码登录
    VerificationLogin() {
      API.Blogin({
        phone: this.para.phone, // 账号
        password: this.para.password, // 密码
        code: this.para.code,
        type: this.para.type,
        language: this.para.language
      })
        .then((res) => {
          if (res.message.success) {
            this.$message({
              message: res.message.message,
              type: 'success',
            });
            this.token = res.message.data;
            cookies.set('token', this.token);
            this.getInfo();
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
            });
          }
        })
        .catch((msg) => {
          this.$message({
            message: msg.message.message,
            type: 'error',
          });
        }).finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.8s;
}

.slide-fade-leave-active {
  transition: all 0.8s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.remenber {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
}
.language {
  position: absolute;
  top:10px;
  right: 20px;
  display: flex;
  align-items: center;
}
.language-img {
  width: 25px;
}
::v-deep .select .el-input--suffix .el-input__inner {
  border: 0;
  color: #333333;
  font-size: 16px;
}
::v-deep .el-select .el-input .el-select__caret {
  font-size: 16px;
}
::v-deep .footer {
  white-space: normal;
}
@media screen and (max-width:800px) {
  .Home {
    min-width: 320px !important;
  }
  .login-box {
    width: 100%;
    height: 450px;
  }
  .login-box .left{
    display: none;
  }
  .login-box .right {
    width: 100%;
    padding: 50px 20px 0 !important;
  }
  ::v-deep .footer {
    font-size: 12px;
    //white-space: normal;
  }
  .text {
    //font-size: 12px;
  }
  .el-button {
    font-size: 13px;
  }
  .content {
    margin-top: 70px;
    align-items: baseline;
  }
}

</style>
