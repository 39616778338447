<template>
  <div class="right" style="padding-top: 105px">
    <!--<div class="language">
      <img class="language-img" src="@/assets/language.png" />
      <el-select
          v-model="language"
          class="select"
          placeholder="请选择"
          style="width: 110px;margin-right:10px"
          @change="handleChange"
      >
        <el-option
            v-for="(item,index) in languageList"
            :key="index"
            :label="item.name"
            :value="item.label"
        ></el-option>
      </el-select>
    </div>-->
    <!--头-->
    <div class="header">
      <!--标题-->
      <h6 class="title">{{ $t('retrieve_password') }}</h6>
      <!--右侧按钮-->
      <div class="title-right">
        <el-button type="text" class="is-black" @click="goBack">{{ $t('return_login') }}</el-button>
      </div>
    </div>

    <!--表单-->
    <el-form
        ref="Forget"
        :model="Forget"
        :rules="rules"
        size="medium"
    >
      <el-form-item prop="phone">
        <el-input
            v-model="Forget.phone"
            v-on:input="getPhoneString"
            :placeholder="$t('phone_number')"
            maxlength="11"
            @input="changeValue"
        />
      </el-form-item>

      <el-form-item key="vf_input" prop="VerificationCode">
        <div class="d-flex flex-align-h-between">
          <el-input
              v-model.trim="Forget.VerificationCode"
              :placeholder="$t('login_verification_code')"
              maxlength="6"
          />

          <div class="ml-20" style="width: 130px;flex:0 0 auto">
            <el-button v-if="AuthCode" type="primary" class="width-full" @click="checkCode()">{{ $t('get_verification_code') }}</el-button>
            <el-button v-else disabled type="primary" class="width-full again-btn">{{ $t('resend_in') }}({{ auth_time }}s)</el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item prop="PassWord">
        <el-input
            v-model.trim="Forget.PassWord"
            :type="passwordType ? 'text' : 'password'"
            :placeholder="$t('password')"
            maxlength="16"
        >
          <svg-icon
              slot="suffix"
              :icon-class="passwordType ? 'eye-open' : 'eye'"
              @click="passwordType = !passwordType"
          />
        </el-input>
      </el-form-item>

      <el-form-item prop="rePassWord">
        <el-input
            v-model.trim="Forget.rePassWord"
            :type="passwordType2 ? 'text' : 'password'"
            :placeholder="$t('repeat_password')"
            maxlength="16"
        >
          <svg-icon
              slot="suffix"
              :icon-class="passwordType2 ? 'eye-open' : 'eye'"
              @click="passwordType2 = !passwordType2"
          />
        </el-input>
      </el-form-item>
    </el-form>
    <!--备注-->
    <div class="remark">{{ $t('numbers_letters_symbols') }}</div>

    <el-button type="primary" size="medium" @click="ResetPassWord" class="width-full">{{ $t('device_confirm') }}</el-button>
  </div>
</template>

<script>
import api from '../../api';

export default {
  name: 'forgetPage',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('again_enter_password')));
      } else if (value !== this.Forget.PassWord) {
        callback(new Error( this.$t('twice_password') + '!'));
      } else {
        callback();
      }
    };
    return {
      passwordType: false,
      passwordType2: false,
      Forget: {
        phone: '', // 手机号,
        VerificationCode: '', // 验证码
        PassWord: '', // 密码
        rePassWord: '', // 重复密码
        language: ''
      },
      language: localStorage.getItem('locale') === 'zh' ? 'zh' : !localStorage.getItem('locale') ? 'zh' : 'en',

      rightViewType: 0,
      AuthCode: true,
      auth_time: 60,
      rules: {
        phone: [
          { required: true, message: this.$t('please_enter_phone_number'), trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: this.$t('please_enter_valid_phone_number'), trigger: 'blur' },
        ],
        PassWord: [
          { required: true, message: this.$t('please_enter_password'), trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: this.$t('numbers_letters_symbols'),
            trigger: 'blur',
          },
        ],
        rePassWord: [
          { required: true, message: this.$t('please_enter_password'), trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ],
        VerificationCode: [
          { required: true, message: this.$t('verification_code'), trigger: 'blur' },
          { pattern: /^\d{6}$/, message: this.$t('correct_verification_code_format'), trigger: 'blur' },
        ],
      },
      languageList: [
        {name: '简体中文',label: 'zh'},
        {name: 'English',label: 'en'}
      ]
    };
  },
  methods: {
    handleChange(e) {
      this.language = e;
      if(e === 'zh') {
        localStorage.setItem('locale', 'zh')
      } else if(e === 'en') {
        localStorage.setItem('locale', 'en')
      } else {
        localStorage.setItem('locale', 'zh')
      }
      this.$i18n.locale = e
      this.$forceUpdate()
      console.log( localStorage.getItem('locale'))
    },
    changeValue(value) {
      this.Forget.phone = value.replace(/[^\d]/g, '');
    },
    getPhoneString() {
      this.Forget.phone = this.Forget.phone.replace(' ', '');
    },
    // 返回登录
    BackLogin() {
      this.$router.push('/login');
    },
    // 验证码手机号格式
    checkPhone() {
      const reg = /^1[3-9][0-9]{9}$/;
      const res = reg.test(this.Forget.phone);
      return res;
    },
    // 获取验证码并判断格式
    checkCode() {
      if (this.Forget.phone.length <= 0) {
        this.$message({
          message: this.$t('mobile_number_cannot_empty'),
          type: 'error',
          duration: 1000,
        });
      } else if (!this.checkPhone()) {
        this.$message({
          message: this.$t('please_enter_valid_phone_number'),
          type: 'error',
          duration: 1000,
        });
      } else {
        this.getCode();
      }
    },
    // 获取验证码
    getCode() {
      api
          .Forget({ phone: this.Forget.phone })
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.AuthCode = false;
              this.auth_time = 60;
              const authTimetimer = setInterval(() => {
                this.auth_time -= 1;
                if (this.auth_time <= 0) {
                  this.AuthCode = true;
                  clearInterval(authTimetimer);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.message.message,
                type: 'warning',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            this.$message({
              message: mgs.message.message,
              type: 'error',
              duration: 1000,
            });
          });
    },
    // 校验验证码格式
    // cheackCode() {
    //   let reg = /^\d{6}$/
    //   let res = reg.test(this.Forget.VerificationCode)
    //   return res
    // },
    // 重置密码
    ResetPassWord() {
      this.$refs.Forget.validate((valid) => {
        if (valid) {
          if (this.language === 'zh') {
            this.Forget.language = 'zh_CN'
          } else {
            this.Forget.language = 'en_US'
          }
          api.reSet({
                phone: this.Forget.phone,
                code: this.Forget.VerificationCode,
                password: this.Forget.PassWord,
            language:this.Forget.language
              })
              .then((res) => {
                if (res.message.success) {
                  this.$message({
                    message: this.$t('password_reset_successful'),
                    type: 'success',
                    duration: 1000,
                  });
                  this.goBack();
                } else {
                  this.$message({
                    message: res.message.message,
                    type: 'error',
                    duration: 1000,
                  });
                }
              })
              .catch((mgs) => {
                this.$message({
                  message: mgs.message.message,
                  type: 'error',
                  duration: 1000,
                });
              });
        }
      });
      // if (this.Forget.phone.length <= 0) {
      //   this.$message({
      //     message: '手机号不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.checkPhone()) {
      //   this.$message({
      //     message: '请输入正确的手机号',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.VerificationCode.length != 0) {
      //   this.$message({
      //     message: '验证码不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (!this.cheackCode()) {
      //   this.$message({
      //     message: '请输入正确验证码格式',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.PassWord != 0 && this.Forget.rePassWord != 0) {
      //   this.$message({
      //     message: '密码不能为空',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else if (this.Forget.PassWord !== this.Forget.rePassWord) {
      //   this.$message({
      //     message: '两次密码不一致',
      //     type: 'error',
      //     duration: 1000
      //   })
      // } else {
      //   api
      //     .reSet(this.Forget)
      //     .then(res => {
      //       if (res.message.success == true) {
      //         this.$message({
      //           message: res.message.message,
      //           type: 'success',
      //           duration: 1000
      //         })
      //         this.BackLogin()
      //       } else {
      //         this.$message({
      //           message: res.message.message,
      //           type: 'error',
      //           duration: 1000
      //         })
      //       }
      //     })
      //     .catch(mgs => {
      //       this.$message({
      //         message: mgs.message.message,
      //         type: 'error',
      //         duration: 1000
      //       })
      //     })
      // }
    },
    goBack() {
      this.$emit('rightViewType', this.rightViewType);
    },
  },
};
</script>
<style lang="scss" scoped>
.language {
  position: absolute;
  top:10px;
  right: 20px;
  display: flex;
  align-items: center;
}
.language-img {
  width: 25px;
}
::v-deep .select .el-input--suffix .el-input__inner {
  border: 0;
  color: #333333;
  font-size: 16px;
}
::v-deep .el-select .el-input .el-select__caret {
  font-size: 16px;
}
::v-deep .el-button--medium {
  padding: 10px 0px;
}
</style>
